/* eslint-disable import/no-cycle */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* syncStatusDaySagas(action) {
  try{
    const { userId, currentDate } = action;

    const [timeline, maps, comparative] = yield all([
      call(api.get, '/status_day/user_activities', { params: { date: currentDate, user_id: userId } }),
      call(api.get, '/status_day/user_pins', { params: { date: currentDate, user_id: userId } }),
      call(api.get, '/status_day/comparative', { params: { date: currentDate, user_id: userId } }),
    ]);

    if(timeline.status === 200 && maps.status === 200 && comparative.status === 200){
      yield put(Actions.syncStatusDaySuccess(
        timeline.data.timeline_categories, 
        timeline.data.timeline_data, 
        maps.data.route,
        maps.data.points,
        maps.data.activities,
        comparative.data.comparative_data,
        comparative.data.comparative_tabs
      ));
    }else{
      yield put(Actions.syncStatusDayError());
    }

  } catch(error) {
    requestError(error)
    yield put(Actions.syncStatusDayError());
  }
};

export default function*() {
  yield all([
    takeLatest(Types.SYNC_STATUS_DAY, syncStatusDaySagas),
  ]);
};
